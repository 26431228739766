@font-face {
  font-family: Astropolis;
  src: url("Astropolis.b4c373e9.ttf");
}

html {
  font-size: 1.25vh;
}

body {
  color: #fff;
  background-color: #272727;
  margin: 0;
}

a {
  color: #fff;
}

a:visited {
  color: #8b8b8b;
}

#app {
  height: 100vh;
}

#home-container {
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 100%;
  margin-left: 0;
  display: flex;
}

#actions-container {
  flex-direction: column;
  padding-bottom: 10rem;
  display: flex;
}

.form-container {
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  display: flex;
}

@media only screen and (width >= 800px) {
  #actions-container {
    flex-direction: row;
    justify-content: center;
  }

  .form-container {
    min-width: 50%;
    max-width: 75%;
  }
}

@media only screen and (width >= 1000px) {
  #home-container {
    width: 80%;
    margin-left: 10%;
  }
}

#messenger-container {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.room-container {
  width: 100%;
  height: 50%;
}

@media only screen and (width >= 1000px) {
  #messenger-container {
    flex-direction: row-reverse;
  }

  .room-container {
    width: 50%;
    height: 100%;
  }
}

h1 {
  margin: 0;
  font-family: monospace;
  font-size: 6rem;
}

#title {
  font-family: Astropolis;
  font-size: 8rem;
  animation: 4s infinite glow;
}

@keyframes glow {
  0% {
    filter: drop-shadow(0 0 .25rem green);
  }

  50% {
    filter: drop-shadow(0 0 1.1rem green);
  }

  100% {
    filter: drop-shadow(0 0 .25rem green);
  }
}

h2 {
  margin: 0;
  font-family: monospace;
  font-size: 5rem;
}

h3 {
  margin: 0;
  font-family: monospace;
  font-size: 3rem;
  font-weight: normal;
}

.room-title {
  font-size: 3rem;
}

#matrix-room-title-container {
  background-color: #0003;
  justify-content: space-between;
  display: flex;
}

#matrix-room-title {
  color: #000;
}

.message-container {
  color: #000;
  cursor: pointer;
  padding: 10px 5px;
}

#tube-room-container .message-container {
  color: #fff;
}

#tube-room-title {
  background-color: #0003;
}

.name {
  opacity: .7;
}

p {
  margin: 0;
  font-family: monospace;
  font-size: 2rem;
}

.form-container p {
  font-size: 3rem;
}

.room-events-container {
  flex-grow: 1;
  overflow-y: scroll;
}

#tube-room-container {
  z-index: 1;
  background-color: #272727;
  flex-direction: column;
  display: flex;
}

#matrix-room-container {
  z-index: 2;
  background-color: #d3d3d3;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

#new-message-container {
  width: 100%;
  display: flex;
}

input {
  font-family: monospace;
  font-size: 1.5rem;
}

#new-message-text {
  flex-grow: 1;
}

#send-button {
  font-family: monospace;
  font-size: 2rem;
}

.forwarded {
  opacity: .5;
  font-size: 1.5rem;
}

label {
  font-family: monospace;
  font-size: 3rem;
  display: block;
}

#create-button {
  display: block;
}

.home-input {
  text-align: center;
  border: none;
  border-radius: 3px;
  width: 90%;
  margin-top: 1rem;
  font-size: 3rem;
}

.home-button {
  border: none;
  border-radius: 3px;
  margin-top: 1rem;
  font-family: monospace;
  font-size: 3rem;
  display: block;
}

.home-button:hover {
  color: #fff;
  background-color: #000;
}

.home-button:active {
  color: #fff;
  background-color: gray;
}

.home-button:disabled {
  color: gray;
  background-color: #d3d3d3;
}

.star {
  z-index: -3;
  filter: drop-shadow(0 0 .2rem #ff0);
  font-size: 1rem;
  position: absolute;
}

#update-button {
  display: block;
}

#tube-link-container {
  flex-direction: column;
  width: 100%;
  display: flex;
}

header {
  flex-direction: column;
  align-items: center;
  display: flex;
}

footer {
  margin-top: 2rem;
}

#profile-container {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.profile-picture {
  cursor: pointer;
  object-fit: cover;
  background-color: #8b8b8b;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
}

.empty {
  justify-content: center;
  align-items: center;
  display: flex;
}

.profile-picture p {
  margin-top: -7%;
  font-size: 4rem;
}

#edit-user-container {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

#buttons-container button {
  height: 100%;
  font-size: 1.5rem;
}
/*# sourceMappingURL=index.9c6c626e.css.map */
